/** @jsx jsx */
import React from 'react';

import { css, jsx } from '@emotion/core';

import PageHeader from '../components/PageHeader';
import SiteTitle from '../containers/SiteTitle';
import Layout from '../layouts';

const NotFoundPage = ({ location }) => (
  <Layout location={location}>
    <SiteTitle title="Not Found" />
    <PageHeader
      header="Not Found"
      css={css`
        text-transform: uppercase;
      `}
    />
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
);

export default NotFoundPage;
